import { ActivatableModule, ModuleGroupId, ModuleOptionType } from "@tm/models"

export function getModuleTitleAndPackage(module: ActivatableModule, translateText: (key: string | number) => string) {
    if (module.externalId) {
        return module.name
    }

    // at carat, TecRMi is called AUTOCOMPACT, thats why we need the translation
    const moduleId = module.id.toLocaleLowerCase() === "tecrmi" ? translateText(12804) : module.id
    let packageName = ""

    if (module.moduleOptions?.find((x) => x.type === ModuleOptionType.Full && x.active)) {
        const activeDemoModulePackage = module.modulePackages?.find((x) =>
            x.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Demo && y.active)
        )
        const activatableDemoModulePackage = module.modulePackages?.find((x) =>
            x.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Demo && y.activatable)
        )

        const activatableFullModulePackage = module.modulePackages?.find((x) =>
            x.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Full && y.activatable)
        )

        if (activatableDemoModulePackage) {
            packageName = activatableDemoModulePackage?.moduleInformation.name
        } else if (activatableFullModulePackage) {
            packageName = activatableFullModulePackage?.moduleInformation.name
        } else if (activeDemoModulePackage) {
            packageName = activeDemoModulePackage?.moduleInformation.name
        }
    }

    return packageName ? `${moduleId} ${packageName}` : moduleId
}

export function getModuleGroupIconName(moduleGroupId: ModuleGroupId) {
    switch (moduleGroupId) {
        case "fastCalculator":
            return "fast-calculator"
        case "gpi":
            return "group"
        case "technicalData":
        case "technicalDataTruck":
            return "tech-data"
        default:
            return ""
    }
}

export function getModuleCompareRoute(moduleGroupId: ModuleGroupId) {
    switch (moduleGroupId) {
        case "gpi":
            return "gpi"
        case "technicalData":
            return "td"
        case "technicalDataTruck":
            return "td-truck"
        default:
            return ""
    }
}
